//////////
// MIXIN
export const i18nMixin = (baseElement) => {
    return class extends baseElement {
        firstUpdated(changedProperties) {
            super.firstUpdated(changedProperties);
            const thisI18n = this;
            if (!thisI18n.t) {
                throw new Error('Localization component MUST have @internalProperty "t"');
            }
            if (!thisI18n.localization) {
                throw new Error('Localization component MUST have @property "localization"');
            }
        }
        updated(changedProperties) {
            super.updated(changedProperties);
            changedProperties.forEach((oldValue, name) => {
                if (name === "localization") {
                    const thisI18n = this;
                    thisI18n.t = Object.keys(thisI18n.t).reduce((_t, k) => {
                        if (thisI18n.localization[k] !== undefined) {
                            _t[k] = thisI18n.localization[k];
                        }
                        else {
                            _t[k] = thisI18n.t[k];
                        }
                        return _t;
                    }, {});
                }
            });
        }
    };
};
