var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/* eslint-disable @typescript-eslint/no-explicit-any */
import { i18nMixin } from "@/mixins/i18n";
import { logger } from "@/sdk";
import defaultLogo from "@img/webex_cc_logo.svg";
import "@momentum-ui/web-components";
import { customElement, html, internalProperty, LitElement, property, query } from "lit-element";
import { nothing } from "lit-html";
import { classMap } from "lit-html/directives/class-map";
import { repeat } from "lit-html/directives/repeat";
import "./comp/NavigationItem";
import style from "./Navigation.scss";
export var Navigation;
(function (Navigation) {
    const DIRECTION = {
        UP: -1,
        DOWN: 1
    };
    const DEFAULT_SCROLL_MARGIN = 20; //px
    /**
     * @element uuip-wc-navigation
     * @fires item-selected
     */
    let Element = class Element extends i18nMixin(LitElement) {
        constructor() {
            super(...arguments);
            this.selectedNavigateTo = null;
            this.items = [];
            this.navtitle = "Cisco Systems Inc.";
            this.logo = defaultLogo;
            this.localization = {};
            this.t = {
                mainSectionLabelText: "Main Navigation List",
                secondarySectionLabelText: "Secondary Navigation List",
                scrollDownText: "Scroll down",
                scrollUpText: "Scroll up",
                hideNavText: "Hide",
                showNavText: "Show",
                reloadText: "Reload"
            };
            this.scrollMargin = DEFAULT_SCROLL_MARGIN; //px
            this.top = [];
            this.bottom = [];
            this.itemSelectedIdx = 0;
            this.showNavList = false;
            this.showTopScrollButton = false;
            this.showBottomScrollButton = false;
            this.bottomClickHandler = (item) => {
                if (item.icon.indexOf("help") >= 0) {
                    this.dispatchEvent(new CustomEvent("ax-help-content", {
                        detail: true,
                        bubbles: true,
                        composed: true
                    }));
                }
                else {
                    this.selected(item.navigateTo);
                }
            };
            this.onPrimaryScroll = (event) => {
                const target = event.target;
                this.showTopScrollButton = target.scrollTop >= this.scrollMargin;
                this.showBottomScrollButton = target.scrollHeight - target.clientHeight - this.scrollMargin >= target.scrollTop;
            };
            this.scrollNavList = (direction) => this.primaryNav.scrollBy({
                top: (direction * this.primaryNav.clientHeight) / 2,
                behavior: "smooth"
            });
            this.handleScrollButtonCreation = () => {
                // dispatching a custom event to initiate the scroll buttons
                if (this.primaryNav) {
                    this.primaryNav.dispatchEvent(new CustomEvent("scroll"));
                }
            };
        }
        connectedCallback() {
            super.connectedCallback();
            window.addEventListener("resize", this.handleScrollButtonCreation);
        }
        disconnectedCallback() {
            window.removeEventListener("resize", this.handleScrollButtonCreation);
            super.disconnectedCallback();
        }
        updated(changedProperties) {
            super.updated(changedProperties);
            changedProperties.forEach((oldValue, name) => {
                if (name === "items" || name === "selectedNavigateTo") {
                    // Updating render collections
                    if (name === "items") {
                        this.top = this.items.filter(i => i.align === "top");
                        this.bottom = this.items.filter(i => i.align === "bottom");
                    }
                    // Updating Select
                    if (this.selectedNavigateTo) {
                        this.itemSelectedIdx = this.items.findIndex(i => i.navigateTo === this.selectedNavigateTo);
                    }
                }
            });
            if (this.showNavList !== changedProperties.get("showNavList")) {
                this.evt("navbar-toggled", { showNavList: this.showNavList });
            }
            // dispatching a custom event to initiate the scroll buttons
            this.handleScrollButtonCreation();
        }
        selected(navigateTo) {
            this.selectedNavigateTo = navigateTo;
            this.evt("item-selected", { navigateTo });
        }
        // ROST: create decorator fot custom event
        evt(name, detail) {
            this.dispatchEvent(new CustomEvent(name, { bubbles: true, composed: true, detail }));
            logger.info("[Navigation]: Dispatching event", name, detail);
        }
        static get styles() {
            return style;
        }
        get logoImgSrc() {
            return this.logo ? this.logo : defaultLogo;
        }
        renderLogo() {
            if (this.logo !== defaultLogo) {
                return html `
          <picture class="logo" aria-label="${this.navtitle}">
            <md-tooltip message="${this.navtitle}">
              <img class="logo--img" src="${this.logoImgSrc}" />
            </md-tooltip>
          </picture>
        `;
            }
        }
        getScrollButtonTitle(dir) {
            return dir === DIRECTION.UP ? this.t.scrollUpText : this.t.scrollDownText;
        }
        renderScrollButton(dir) {
            return html `
        <md-button
          @click=${() => this.scrollNavList(dir)}
          ariaLabel="${this.getScrollButtonTitle(dir)}"
          role="scrollbar"
          title="${this.getScrollButtonTitle(dir)}"
          class="${classMap({ top: dir === DIRECTION.UP, bottom: DIRECTION.UP, "nav-scroll-button": true })}"
          expanded
          hasRemoveStyle
        >
          <md-icon
            class="nav-scroll-icon"
            name="${dir === DIRECTION.UP ? "icon-arrow-up_24" : "icon-arrow-down_24"}"
            title="${this.getScrollButtonTitle(dir)}"
          ></md-icon>
        </md-button>
      `;
        }
        renderHamburgerButton(isNavShown) {
            return html `
        <md-icon
          tabindex="0"
          ariaLabel="${isNavShown ? this.t.hideNavText : this.t.showNavText}"
          name="${isNavShown ? "icon-cancel_24" : "icon-list-menu_24"}"
          class="nav-button"
          @click=${() => {
                this.showNavList = !isNavShown;
                if (this.showNavList) {
                    this.setAttribute("showNavList", "");
                }
                else {
                    this.removeAttribute("showNavList");
                }
            }}
        ></md-icon>
      `;
        }
        render() {
            return html `
        <nav class="column ${classMap({ shadow: this.showNavList })}">
          <div class="top-container">
            ${this.renderLogo()}
            <div class="hamburger-container">
              ${this.renderHamburgerButton(this.showNavList)}
            </div>
          </div>
          <div class="${classMap({ "show-nav-list": this.showNavList, "hidable-container": true })}">
            <div class="column container">
              <div class="scroll-button-placer">
                ${this.showTopScrollButton ? this.renderScrollButton(DIRECTION.UP) : nothing}
              </div>
              <ul
                @scroll=${this.onPrimaryScroll}
                id="primaryNav"
                aria-label="${this.t.mainSectionLabelText}"
                role="listbox"
                class="nav-list primary"
              >
                ${repeat(this.top, (item, index) => html `
                    <li class="nav-list__item">
                      <md-tooltip message="${item.label}">
                        <uuip-wc-navigation-item
                          .item="${item}"
                          ?selected="${this.itemSelectedIdx === index}"
                          @selected="${(e) => this.selected(e.detail.item.navigateTo)}"
                        ></uuip-wc-navigation-item>
                      </md-tooltip>
                    </li>
                  `)}
              </ul>

              <div class="scroll-button-placer">
                ${this.showBottomScrollButton ? this.renderScrollButton(DIRECTION.DOWN) : nothing}
              </div>
              <div>
                <!-- Bottom of the navigation bar is designated for quick links - they will not have 'selected' state -->
                <ul aria-label="${this.t.secondarySectionLabelText}" role="listbox" class="nav-list secondary">
                  ${repeat(this.bottom, (item, index) => html `
                      <li class="nav-list__item">
                        <md-tooltip message="${item.label}">
                          <uuip-wc-navigation-item
                            .item="${item}"
                            ?selected="${false}"
                            @selected="${(e) => this.bottomClickHandler(e.detail.item)}"
                          ></uuip-wc-navigation-item>
                        </md-tooltip>
                      </li>
                    `)}
                </ul>
                <!-- Display reload icon if uuip loaded in an iframe -->
                ${window !== window.top
                ? html `
                      <md-tooltip message="${this.t.reloadText}">
                        <md-button
                          @click=${() => location.reload()}
                          ariaLabel="${this.t.reloadText}"
                          class="reload-button"
                          expanded
                          hasRemoveStyle
                          tabindex="0"
                        >
                          <md-icon
                            aria-label="${this.t.reloadText}"
                            title="${this.t.reloadText}"
                            name="icon-refresh_24"
                            class="reload-icon"
                          ></md-icon>
                        </md-button>
                      </md-tooltip>
                    `
                : nothing}
                <!-- webex icon for mobile/connectors view -->
                ${this.renderLogo()}
              </div>
            </div>
          </div>
        </nav>
      `;
        }
    };
    __decorate([
        property({ type: String })
    ], Element.prototype, "selectedNavigateTo", void 0);
    __decorate([
        property({ type: Array })
    ], Element.prototype, "items", void 0);
    __decorate([
        property({ type: String })
    ], Element.prototype, "navtitle", void 0);
    __decorate([
        property({ type: String })
    ], Element.prototype, "logo", void 0);
    __decorate([
        property({ type: Object })
    ], Element.prototype, "localization", void 0);
    __decorate([
        internalProperty()
    ], Element.prototype, "t", void 0);
    __decorate([
        property({ type: Number })
    ], Element.prototype, "scrollMargin", void 0);
    __decorate([
        internalProperty()
    ], Element.prototype, "top", void 0);
    __decorate([
        internalProperty()
    ], Element.prototype, "bottom", void 0);
    __decorate([
        internalProperty()
    ], Element.prototype, "itemSelectedIdx", void 0);
    __decorate([
        internalProperty()
    ], Element.prototype, "showNavList", void 0);
    __decorate([
        internalProperty()
    ], Element.prototype, "showTopScrollButton", void 0);
    __decorate([
        internalProperty()
    ], Element.prototype, "showBottomScrollButton", void 0);
    __decorate([
        query("#primaryNav")
    ], Element.prototype, "primaryNav", void 0);
    Element = __decorate([
        customElement("uuip-wc-navigation")
    ], Element);
    Navigation.Element = Element;
})(Navigation || (Navigation = {}));
