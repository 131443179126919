var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { i18nMixin } from "@/mixins/i18n";
import "@momentum-ui/web-components";
import { customElement, html, internalProperty, LitElement, property, query } from "lit-element";
import { nothing } from "lit-html";
import { classMap } from "lit-html/directives/class-map";
import style from "./ErrorDetail.scss";
export var ErrorDetail;
(function (ErrorDetail) {
    const COPY_TIMEOUT = 1000;
    let Element = class Element extends i18nMixin(LitElement) {
        constructor() {
            super(...arguments);
            this.show = false;
            this.isCopied = false;
            this.header = "";
            this.message = "";
            this.trackingId = "";
            this.localization = {};
            this.t = {
                errorLessButtonText: "Show Less",
                errorMoreButtonText: "Show More",
                errorDetailsText: "Error Details",
                trackingIdTitleText: "Copy trackingId",
                trackingIdDescriptionText: "Please share the Tracking ID with your administrator to investigate the issue.",
                trackingIdLabelText: "Tracking ID",
                copyText: "Copy",
                copiedText: "Copied"
            };
            this.handleDetailToggle = () => {
                var _a;
                this.show = (_a = this.details) === null || _a === void 0 ? void 0 : _a.open;
                this.isCopied = false;
            };
        }
        firstUpdated(changedProperties) {
            super.firstUpdated(changedProperties);
            if (this.details) {
                this.details.addEventListener("toggle", this.handleDetailToggle);
                this.details.open = this.show;
            }
        }
        disconnectedCallback() {
            super.disconnectedCallback();
            this.details.removeEventListener("toggle", this.handleDetailToggle);
        }
        copyTrackingId() {
            const text = this.trackID.innerText;
            const input_temp = document.createElement("input");
            input_temp.value = text;
            document.body.appendChild(input_temp);
            input_temp.select();
            document.execCommand("copy");
            document.body.removeChild(input_temp);
            this.isCopied = true;
            setTimeout(() => {
                this.isCopied = false;
            }, COPY_TIMEOUT);
        }
        static get styles() {
            return [style];
        }
        renderSummary() {
            if (this.format === "toaster" || this.format === "default") {
                return html `
          <summary role="button" class="trackingid-summary" tabindex="0">
            <md-icon aria-hidden="true" name="icon-error_12" title="failed icon"></md-icon>
            <span class="failed-details-text">${this.header}</span>
            <span part="error-more-btn" class="failed-details-link">
              ${this.show ? this.t.errorLessButtonText : this.t.errorMoreButtonText}
            </span>
          </summary>
        `;
            }
            return html `
        <summary class="trackingid-summary error-details-link" tabindex="0">
          ${this.t.errorDetailsText}
        </summary>
      `;
        }
        renderDescription() {
            if (this.format !== "toaster") {
                return html `
          <div class="trackingid-description">
            ${this.t.trackingIdDescriptionText}
          </div>
        `;
            }
            return nothing;
        }
        renderCopyButtonIcon() {
            if (this.format === "modal") {
                return html `
          <md-icon name=${this.isCopied ? "icon-check_14" : "icon-copy_14"}></md-icon>
        `;
            }
            return html `
        <md-icon name=${this.isCopied ? "icon-check_12" : "icon-copy_12"}></md-icon>
      `;
        }
        render() {
            return html `
        <details
          class="${classMap({
                "error-details": true,
                centered: this.format === "default" || this.format === undefined
            })}"
        >
          ${this.renderSummary()}

          <div
            class="${classMap({
                "trackingid-container": true,
                [`${this.format}`]: true
            })}"
          >
            ${this.renderDescription()}

            <div
              class="${classMap({
                "trackingid-error-details-wrap": true,
                [`${this.format}`]: true
            })}"
            >
              <div class="tracking-input-wrap">
                <label class="trackingid-label">${this.t.trackingIdLabelText}:</label>
                <div id="trackingid-input" class="trackingid-input">${this.trackingId}</div>
              </div>
              <span class="copy-button-container">
                <md-tooltip placement="top" message="${!this.isCopied ? this.t.copyText : this.t.copiedText}">
                  <md-button
                    id="copy-trackingid-icon"
                    class="copy-tracking-icon"
                    circle
                    @click=${() => this.copyTrackingId()}
                    ariaLabel=${this.isCopied ? this.t.copiedText : this.t.copyText}
                    ariaLive="assertive"
                    variant="${this.isCopied ? "green" : "white"}"
                  >
                    ${this.renderCopyButtonIcon()}
                  </md-button>
                </md-tooltip>
              </span>
            </div>
          </div>
        </details>
      `;
        }
    };
    __decorate([
        property({ type: String })
    ], Element.prototype, "format", void 0);
    __decorate([
        property({ type: Boolean })
    ], Element.prototype, "show", void 0);
    __decorate([
        property({ type: Boolean })
    ], Element.prototype, "isCopied", void 0);
    __decorate([
        property({ type: String })
    ], Element.prototype, "header", void 0);
    __decorate([
        property({ type: String })
    ], Element.prototype, "message", void 0);
    __decorate([
        property({ type: String })
    ], Element.prototype, "trackingId", void 0);
    __decorate([
        property({ type: Object })
    ], Element.prototype, "localization", void 0);
    __decorate([
        internalProperty()
    ], Element.prototype, "t", void 0);
    __decorate([
        query(".error-details")
    ], Element.prototype, "details", void 0);
    __decorate([
        query(".trackingid-input")
    ], Element.prototype, "trackID", void 0);
    Element = __decorate([
        customElement("uuip-wc-error-detail")
    ], Element);
    ErrorDetail.Element = Element;
})(ErrorDetail || (ErrorDetail = {}));
