var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import "@momentum-ui/web-components";
import { customElement, internalProperty, LitElement, property } from "lit-element";
import { html } from "lit-html";
import { classMap } from "lit-html/directives/class-map";
import { styleMap } from "lit-html/directives/style-map";
import style from "./NavigationItem.scss";
const DEFAULT_ICON_SIZE = 20;
const MAX_NOTIFICATION_COUNT = "99+";
const isImageUrl = (url) => {
    if (!url || url.length < 4) {
        return false;
    }
    const validImageExtensions = ["png", "gif", "jpg", "jpeg", "svg", "bmp", "ico"];
    const image = url.substring(url.lastIndexOf("/")).toLowerCase();
    return validImageExtensions.find(ext => image.includes(ext));
};
export var NavigationItem;
(function (NavigationItem) {
    let _uid = 0;
    /**
     * @element uuip-wc-navigation-item
     * @fires selected
     */
    let Element = class Element extends LitElement {
        constructor() {
            super(...arguments);
            this.selected = false;
            this.popoverCount = null;
            this.uid = _uid++;
            this.isImgLoaded = false;
            this.isImgError = false;
        }
        connectedCallback() {
            super.connectedCallback();
        }
        get label() {
            return this.item && this.item.label ? this.item.label : "";
        }
        get iconSize() {
            return this.item && this.item.iconSize ? this.item.iconSize : DEFAULT_ICON_SIZE;
        }
        get isMomentum() {
            return this.item && this.item.iconType === "momentum";
        }
        get isImg() {
            return this.item && this.item.iconType === "other" && !!isImageUrl(this.item.icon);
        }
        get btnCssClassInfo() {
            return {
                "nav-button__imaged": this.isImg,
                "nav-button__selected": this.selected
            };
        }
        static get styles() {
            return style;
        }
        onBtnClick() {
            this.dispatchEvent(new CustomEvent("selected", {
                detail: {
                    item: this.item
                }
            }));
        }
        render() {
            return html `
        <md-button
          role="navigation"
          id="navigation-item-${this.uid}"
          ariaLabel=${this.label}
          @click=${() => this.onBtnClick()}
          class="nav-button ${classMap(this.btnCssClassInfo)}"
          expanded
          hasRemoveStyle
        >
          ${this.renderIcon()} ${this.renderBadge()}
        </md-button>
      `;
        }
        renderBadge() {
            if (this.item.notificationCount) {
                const count = this.item.notificationCount < 99 ? this.item.notificationCount : MAX_NOTIFICATION_COUNT;
                return html `
          <md-badge class="wc-notification-count" color="darkred" small id="wc-notification-count">
            <span>${count}</span>
          </md-badge>
        `;
            }
        }
        renderIcon() {
            if (this.isMomentum) {
                return html `
          <md-icon
            ariaLabel=${this.item.icon}
            name="icon-${this.item.icon}_${this.iconSize}"
            class="nav-button-content"
          ></md-icon>
        `;
            }
            if (this.isImg) {
                return html `
          <div
            class="nav-button-content ${classMap({
                    hidden: this.isImgError,
                    "img-loading": !this.isImgError && !this.isImgLoaded
                })}"
          >
            <img
              src="${this.item.icon}"
              alt="${this.label}"
              style="${styleMap({
                    "max-height": `${this.iconSize}px`,
                    "max-width": `${this.iconSize}px`
                })}"
              @load="${() => {
                    this.isImgError = false;
                    this.isImgLoaded = true;
                }}"
              @error="${() => (this.isImgError = true)}"
            />
          </div>
          <md-icon
            name="icon-warning_20"
            title=${this.label}
            class="nav-button-content ${classMap({ hidden: !this.isImgError })}"
          ></md-icon>
        `;
            }
            return html `
        <span>${this.label}</span>
      `;
        }
    };
    __decorate([
        property({ type: Object })
    ], Element.prototype, "item", void 0);
    __decorate([
        property({ type: Boolean })
    ], Element.prototype, "selected", void 0);
    __decorate([
        property({ type: Number })
    ], Element.prototype, "popoverCount", void 0);
    __decorate([
        internalProperty()
    ], Element.prototype, "uid", void 0);
    __decorate([
        internalProperty()
    ], Element.prototype, "isImgLoaded", void 0);
    __decorate([
        internalProperty()
    ], Element.prototype, "isImgError", void 0);
    Element = __decorate([
        customElement("uuip-wc-navigation-item")
    ], Element);
    NavigationItem.Element = Element;
})(NavigationItem || (NavigationItem = {}));
