var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import "../../components/Notifications/components/ModalNotification/ModalNotificationsHost";
import "../../components/Notifications/components/ToasterNotification/ToasterNotificationsHost";
import { i18nMixin } from "@/mixins/i18n";
import "@momentum-ui/web-components";
import { Notifications } from "@uuip/unified-ui-platform-sdk";
import { customElement, html, internalProperty, LitElement, property } from "lit-element";
import { nothing } from "lit-html";
import { styleMap } from "lit-html/directives/style-map";
import style from "./NotificationsHost.scss";
export var NotificationsHost;
(function (NotificationsHost) {
    /**
     * @element uuip-wc-notifications-host
     * @fires ignore-notifications
     */
    let Element = class Element extends i18nMixin(LitElement) {
        constructor() {
            super(...arguments);
            this.toasterHostPosition = "top-right";
            this.toasterHostHeight = "height-auto";
            this.localization = {};
            this.t = {
                ignoreButtonText: "Ignore Notifications"
            };
            this.activatedAutodismiss = [];
            this.activatedAcknowledge = [];
            this.updateCollections = () => {
                this.activatedAutodismiss = [...this.notificationsService.activated.autodismiss];
                this.activatedAcknowledge = [...this.notificationsService.activated.acknowledge];
            };
        }
        static get styles() {
            return style;
        }
        disconnectedCallback() {
            super.disconnectedCallback();
            this.unbindServiceEvents();
        }
        firstUpdated(changedProperties) {
            super.firstUpdated(changedProperties);
            this.updateCollections();
            this.bindServiceEvents();
        }
        bindServiceEvents() {
            // Listening for all service status events
            Notifications.ServiceMeta.STATUS_EVENTS.forEach(eventName => this.notificationsService.addEventListener(eventName, this.updateCollections));
        }
        unbindServiceEvents() {
            // Stop Listening for all service status events
            Notifications.ServiceMeta.STATUS_EVENTS.forEach(eventName => this.notificationsService.removeEventListener(eventName, this.updateCollections));
        }
        ignoreNotifications() {
            // Ignoring as pended-silent through service
            const pendingNotifications = this.notificationsService.pendingAll();
            // Emitting
            this.dispatchEvent(new CustomEvent("ignore-notifications", {
                detail: { notifications: pendingNotifications }
            }));
        }
        renderConditionalToasterIgnoreButton(slotName) {
            if (this.notificationsService &&
                this.activatedAutodismiss.length >= this.notificationsService.config.ACTIVATED_ITEM_MODE_LIMIT.autodismiss) {
                return html `
          <md-button
            class="ignore-all-toasts"
            slot="${slotName}"
            ariaLabel="${this.t.ignoreButtonText}"
            variant="white"
            width="100%"
            size="40"
            @click="${() => this.ignoreNotifications()}"
          >
            <md-icon
              slot="icon"
              name="alert-muted_16"
              style=${styleMap({ color: "var(--md-hyperlink-text-color)" })}
            ></md-icon>
            <span slot="text">${this.t.ignoreButtonText}</span>
          </md-button>
        `;
            }
            return nothing;
        }
        render() {
            return html `
        <uuip-wc-toaster-notifications-host
          .notifications="${this.activatedAutodismiss}"
          .position="${this.toasterHostPosition}"
          .height="${this.toasterHostHeight}"
        >
          ${this.renderConditionalToasterIgnoreButton("after-list")}
        </uuip-wc-toaster-notifications-host>
        <uuip-wc-modal-notifications-host
          .notifications="${this.activatedAcknowledge}"
        ></uuip-wc-modal-notifications-host>
      `;
        }
    };
    __decorate([
        property({ type: Object })
    ], Element.prototype, "notificationsService", void 0);
    __decorate([
        property({ type: String })
    ], Element.prototype, "toasterHostPosition", void 0);
    __decorate([
        property({ type: String })
    ], Element.prototype, "toasterHostHeight", void 0);
    __decorate([
        property({ type: Object })
    ], Element.prototype, "localization", void 0);
    __decorate([
        internalProperty()
    ], Element.prototype, "t", void 0);
    __decorate([
        internalProperty()
    ], Element.prototype, "activatedAutodismiss", void 0);
    __decorate([
        internalProperty()
    ], Element.prototype, "activatedAcknowledge", void 0);
    Element = __decorate([
        customElement("uuip-wc-notifications-host")
    ], Element);
    NotificationsHost.Element = Element;
})(NotificationsHost || (NotificationsHost = {}));
