var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { customElement, html, LitElement, property } from "lit-element";
import { classMap } from "lit-html/directives/class-map";
import style from "./CollapseToggler.scss";
export var CollapseToggler;
(function (CollapseToggler) {
    let Event;
    (function (Event) {
        Event["Toggle"] = "toggle";
    })(Event = CollapseToggler.Event || (CollapseToggler.Event = {}));
    let Direction;
    (function (Direction) {
        Direction["Left"] = "left";
        Direction["Right"] = "right";
    })(Direction = CollapseToggler.Direction || (CollapseToggler.Direction = {}));
    /**
     * @element uuip-wc-collapse-toggler
     * @fires toggle
     */
    let Element = class Element extends LitElement {
        constructor() {
            super(...arguments);
            this.collapsed = false;
            this.direction = Direction.Left;
            this.visible = true;
            this.hovered = false;
        }
        toggle() {
            this.dispatchEvent(new CustomEvent(CollapseToggler.Event.Toggle, {
                detail: {
                    collapsed: !this.collapsed
                }
            }));
        }
        static get styles() {
            return [style];
        }
        get iconName() {
            const ICON_NAME_LEFT = "icon-arrow-left-optical_8";
            const ICON_NAME_RIGHT = "icon-arrow-right-optical_8";
            if (this.collapsed) {
                if (this.direction === Direction.Left) {
                    return ICON_NAME_RIGHT;
                }
                if (this.direction === Direction.Right) {
                    return ICON_NAME_LEFT;
                }
            }
            else {
                if (this.direction === Direction.Left) {
                    return ICON_NAME_LEFT;
                }
                if (this.direction === Direction.Right) {
                    return ICON_NAME_RIGHT;
                }
            }
        }
        render() {
            return html `
        <span class="toggler ${classMap({ visible: this.visible || this.collapsed })}" @click="${() => this.toggle()}">
          <md-icon name="${this.iconName}"></md-icon>
        </span>
      `;
        }
    };
    __decorate([
        property({ type: Boolean })
    ], Element.prototype, "collapsed", void 0);
    __decorate([
        property({ type: String, reflect: true })
    ], Element.prototype, "direction", void 0);
    __decorate([
        property({ type: Boolean })
    ], Element.prototype, "visible", void 0);
    __decorate([
        property({ type: Boolean })
    ], Element.prototype, "hovered", void 0);
    Element = __decorate([
        customElement("uuip-wc-collapse-toggler")
    ], Element);
    CollapseToggler.Element = Element;
})(CollapseToggler || (CollapseToggler = {}));
