var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { MaximizeAreaHost } from "../../components/MaximizeArea/MaximizeAreaHost";
import { i18nMixin } from "@/mixins/i18n";
import { logger } from "@/sdk";
import { createElementTransitionEndPromise, createTimeoutPromise } from "@/utils/helpers";
import { WINDOW_LISTENERS_HUB } from "@/utils/WindowListenersHub";
import { css, customElement, html, internalProperty, LitElement, property } from "lit-element";
import styles from "./MaximizeAreaBlock.scss";
export var MaximizeAreaBlock;
(function (MaximizeAreaBlock) {
    MaximizeAreaBlock.TRANSITION_FAILED_TIMEOUT = 2000; //ms
    const FPS_TIMEOUT = 1000 / 60;
    // Block <=> Host communication events
    let AuxEvents;
    (function (AuxEvents) {
        AuxEvents["ToggleRequest"] = "toggle-maximizing-block-request";
        AuxEvents["DisconnectRequest"] = "disconnect-maximizing-block-request";
        AuxEvents["MaximizeStart"] = "maximizing-block-start-request";
        AuxEvents["MaximizeEnd"] = "maximizing-block-end-request";
        AuxEvents["MinimizeStart"] = "minimizing-block-start-request";
        AuxEvents["MinimizeEnd"] = "minimizing-block-end-request";
    })(AuxEvents = MaximizeAreaBlock.AuxEvents || (MaximizeAreaBlock.AuxEvents = {}));
    let counter = 0;
    /**
     * @element uuip-wc-maximize-area-block
     */
    let Element = class Element extends i18nMixin(LitElement) {
        constructor() {
            super(...arguments);
            this.localization = {};
            this.t = {
                maximizeText: "Maximize",
                minimizeText: "Minimize"
            };
            this.isMaximized = false;
            this.onMaximizeAreaResponse = (e) => {
                const { areaName, blockName, rect } = e.detail;
                if (areaName && blockName && this.maximizeToAreaName === areaName && this.blockName === blockName) {
                    const selfRect = this.getBoundingClientRect();
                    Promise.resolve()
                        .then(() => {
                        // Aux hub start
                        this.dispatchAnimationState(AuxEvents.MaximizeStart, { areaName, blockName });
                    })
                        .then(() => {
                        // Setup as Fixed
                        this.setAttribute("maximize", "");
                        // Positioning on self initial coords
                        this.style.cssText = css `
              top: ${selfRect.top}px;
              left: ${selfRect.left}px;
              width: ${selfRect.width}px;
              height: ${selfRect.height}px;
            `.cssText;
                    })
                        // delay for styles apply
                        .then(() => createTimeoutPromise(FPS_TIMEOUT))
                        // Setup transition type
                        .then(() => {
                        this.setAttribute("toggling", "");
                    })
                        // delay for styles apply
                        .then(() => createTimeoutPromise(FPS_TIMEOUT))
                        // Setup animation to host area coords
                        .then(() => {
                        this.style.cssText = css `
              top: ${selfRect.top}px;
              left: ${selfRect.left}px;
              /**/
              transform: translate3d(${rect.left - selfRect.left}px, ${rect.top - selfRect.top}px, 0);
              width: ${rect.width}px;
              height: ${rect.height}px;
            `.cssText;
                    })
                        // awaiting transition end event
                        .then(() => createElementTransitionEndPromise(this, "transform", MaximizeAreaBlock.TRANSITION_FAILED_TIMEOUT))
                        // delay for styles apply
                        .then(() => createTimeoutPromise(FPS_TIMEOUT))
                        // Hold on host area coords
                        .then(() => {
                        const bodyRect = document.body.getBoundingClientRect();
                        this.removeAttribute("toggling");
                        this.style.cssText = css `
              top: ${rect.top}px;
              left: ${rect.left}px;
              width: auto;
              height: auto;
              bottom: ${bodyRect.bottom - rect.bottom}px;
              right: ${bodyRect.right - rect.right}px;
            `.cssText;
                    })
                        // delay for styles apply
                        .then(() => createTimeoutPromise(FPS_TIMEOUT))
                        .then(() => {
                        this.isMaximized = true;
                    })
                        .then(() => {
                        // Aux hub end
                        this.dispatchAnimationState(AuxEvents.MaximizeEnd, { areaName, blockName });
                    });
                }
            };
            this.onMinimizeAreaResponse = (e) => {
                var _a;
                const { areaName, blockName, rect } = e.detail;
                if (areaName && blockName && this.maximizeToAreaName === areaName && this.blockName === blockName) {
                    const parentRect = (_a = this.parentElement) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
                    if (parentRect) {
                        Promise.resolve()
                            .then(() => {
                            // Aux hub start
                            this.dispatchAnimationState(AuxEvents.MinimizeStart, { areaName, blockName });
                        })
                            .then(() => {
                            // Positioning on host area coords (fixed when maximized)
                            this.style.cssText = css `
                top: ${rect.top}px;
                left: ${rect.left}px;
                width: ${rect.width}px;
                height: ${rect.height}px;
              `.cssText;
                        })
                            // delay for styles apply
                            .then(() => createTimeoutPromise(FPS_TIMEOUT))
                            // Setup transition type
                            .then(() => {
                            this.setAttribute("toggling", "");
                        })
                            // delay for styles apply
                            .then(() => createTimeoutPromise(FPS_TIMEOUT))
                            // Setup animation to self coords (by parent)
                            .then(() => {
                            this.style.cssText = css `
                top: ${rect.top}px;
                left: ${rect.left}px;
                /**/
                transform: translate3d(${parentRect.left - rect.left}px, ${parentRect.top - rect.top}px, 0);
                width: ${parentRect.width}px;
                height: ${parentRect.height}px;
              `.cssText;
                        })
                            // awaiting transition end event
                            .then(() => createElementTransitionEndPromise(this, "transform", MaximizeAreaBlock.TRANSITION_FAILED_TIMEOUT))
                            // delay for styles apply
                            .then(() => createTimeoutPromise(FPS_TIMEOUT))
                            // Cleanup to self coords
                            .then(() => {
                            this.removeAttribute("toggling");
                            this.style.cssText = css ``.cssText;
                            this.removeAttribute("maximize");
                        })
                            // delay for styles apply
                            .then(() => createTimeoutPromise(FPS_TIMEOUT))
                            .then(() => {
                            this.isMaximized = false;
                        })
                            .then(() => {
                            // Aux hub end
                            this.dispatchAnimationState(AuxEvents.MinimizeEnd, { areaName, blockName });
                        });
                    }
                }
            };
            this.onResizeAreaResponse = (e) => {
                const { areaName, blockName, rect } = e.detail;
                if (this.isMaximized &&
                    areaName &&
                    blockName &&
                    this.maximizeToAreaName === areaName &&
                    this.blockName === blockName) {
                    const bodyRect = document.body.getBoundingClientRect();
                    this.style.cssText = css `
          top: ${rect.top}px;
          left: ${rect.left}px;
          width: auto;
          height: auto;
          bottom: ${bodyRect.bottom - rect.bottom}px;
          right: ${bodyRect.right - rect.right}px;
        `.cssText;
                }
            };
        }
        connectedCallback() {
            super.connectedCallback();
            this.blockName = `maximize-block-${counter}`;
            counter++;
            WINDOW_LISTENERS_HUB.on(MaximizeAreaHost.AuxEvents.MaximizeResponse, this.onMaximizeAreaResponse);
            WINDOW_LISTENERS_HUB.on(MaximizeAreaHost.AuxEvents.MinimizeResponse, this.onMinimizeAreaResponse);
            WINDOW_LISTENERS_HUB.on(MaximizeAreaHost.AuxEvents.ResizeResponse, this.onResizeAreaResponse);
        }
        disconnectedCallback() {
            super.disconnectedCallback();
            this.dispatchDisconnectRequest();
            WINDOW_LISTENERS_HUB.off(MaximizeAreaHost.AuxEvents.MaximizeResponse, this.onMaximizeAreaResponse);
            WINDOW_LISTENERS_HUB.off(MaximizeAreaHost.AuxEvents.MinimizeResponse, this.onMinimizeAreaResponse);
            WINDOW_LISTENERS_HUB.off(MaximizeAreaHost.AuxEvents.ResizeResponse, this.onResizeAreaResponse);
        }
        updated(changedProperties) {
            super.updated(changedProperties);
            changedProperties.forEach((oldValue, name) => {
                if (name === "blockName" && this.blockName) {
                    this.setAttribute("block-name", this.blockName);
                }
            });
        }
        static get styles() {
            return styles;
        }
        dispatchAuxHubUpdate(eventName, { areaName, blockName }) {
            WINDOW_LISTENERS_HUB.dispatch(new CustomEvent(eventName, {
                detail: {
                    areaName,
                    blockName
                }
            }));
        }
        dispatchToggleRequest() {
            if (this.maximizeToAreaName && this.blockName) {
                this.dispatchAuxHubUpdate(AuxEvents.ToggleRequest, {
                    areaName: this.maximizeToAreaName,
                    blockName: this.blockName
                });
                const event = new CustomEvent("toggle-maximized-event", {
                    detail: {
                        isMaximized: this.isMaximized
                    }
                });
                window.dispatchEvent(event);
            }
            else {
                logger.error(`[MaximizeAreaBlock] Maximizing/Minimizing Area or Block name is not defined...`);
            }
        }
        dispatchDisconnectRequest() {
            if (this.maximizeToAreaName && this.blockName) {
                this.dispatchAuxHubUpdate(AuxEvents.DisconnectRequest, {
                    areaName: this.maximizeToAreaName,
                    blockName: this.blockName
                });
            }
            else {
                logger.error(`[MaximizeAreaBlock] Maximizing/Minimizing Area or Block name is not defined...`);
            }
        }
        dispatchAnimationState(eventName, { areaName, blockName }) {
            this.dispatchAuxHubUpdate(eventName, { areaName, blockName });
        }
        render() {
            return html `
        <section class="maximize-controls">
          <section class="maximize-controls-title">
            <slot name="title"></slot>
          </section>
          <section class="maximize-controls-buttons">
            <md-button
              @click="${() => this.dispatchToggleRequest()}"
              variant="secondary"
              circle
              ariaLabel="${this.isMaximized ? this.t.minimizeText : this.t.maximizeText}"
            >
              <md-icon name="${this.isMaximized ? "minimize_20" : "maximize_20"}"></md-icon>
            </md-button>
          </section>
        </section>
        <section class="maximize-content">
          <slot></slot>
        </section>
      `;
        }
    };
    __decorate([
        property({ type: String })
    ], Element.prototype, "maximizeToAreaName", void 0);
    __decorate([
        property({ type: Object })
    ], Element.prototype, "localization", void 0);
    __decorate([
        internalProperty()
    ], Element.prototype, "t", void 0);
    __decorate([
        internalProperty()
    ], Element.prototype, "blockName", void 0);
    __decorate([
        internalProperty()
    ], Element.prototype, "isMaximized", void 0);
    Element = __decorate([
        customElement("uuip-wc-maximize-area-block")
    ], Element);
    MaximizeAreaBlock.Element = Element;
})(MaximizeAreaBlock || (MaximizeAreaBlock = {}));
