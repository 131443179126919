var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { customElement, html, LitElement } from "lit-element";
import style from "./ContentLoading.scss";
export var ContentLoading;
(function (ContentLoading) {
    ContentLoading.DEFAULT_LOADING_TEXT = "Please wait while the data is loading";
    /**
     * @element uuip-wc-content-loading
     */
    let Element = class Element extends LitElement {
        static get styles() {
            return [style];
        }
        render() {
            return html `
        <div class="content-loading">
          <div class="content-loading-text">
            <slot>${ContentLoading.DEFAULT_LOADING_TEXT}</slot>
          </div>
          <md-loading class="md-loading"></md-loading>
        </div>
      `;
        }
    };
    Element = __decorate([
        customElement("uuip-wc-content-loading")
    ], Element);
    ContentLoading.Element = Element;
})(ContentLoading || (ContentLoading = {}));
