var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import "../../components/MaximizeArea/MaximizeAreaBlock";
import { customElement, html, LitElement, property } from "lit-element";
import style from "./WidgetWrapper.scss";
export var WidgetWrapper;
(function (WidgetWrapper) {
    /**
     * @element uuip-wc-widget-wrapper
     */
    let Element = class Element extends LitElement {
        constructor() {
            super(...arguments);
            this.title = "";
            this.id = "";
            this.maximizeBlockLocalization = {};
            this.setTitleHandler = (e) => {
                this.title = e.detail.title;
            };
        }
        static get styles() {
            return [style];
        }
        connectedCallback() {
            super.connectedCallback();
            if (this.id) {
                window.addEventListener(this.id, this.setTitleHandler);
            }
        }
        disconnectedCallback() {
            if (this.id) {
                window.removeEventListener(this.id, this.setTitleHandler);
            }
        }
        render() {
            return html `
        <section class="ax-widget--section" part="widget-section">
          ${!this.maximizeToAreaName
                ? html `
                <div class="ax-widget--header ax-widget--header__bordered">
                  <h3>${this.title}</h3>
                </div>
                <div class="ax-widget--slotted">
                  <slot></slot>
                </div>
              `
                : html `
                <uuip-wc-maximize-area-block
                  .maximizeToAreaName="${this.maximizeToAreaName}"
                  .localization="${this.maximizeBlockLocalization}"
                >
                  <div class="ax-widget--header" slot="title">
                    <h3>${this.title}</h3>
                  </div>
                  <div class="ax-widget--slotted">
                    <slot></slot>
                  </div>
                </uuip-wc-maximize-area-block>
              `}
        </section>
      `;
        }
    };
    __decorate([
        property({ type: String })
    ], Element.prototype, "title", void 0);
    __decorate([
        property({ type: String })
    ], Element.prototype, "maximizeToAreaName", void 0);
    __decorate([
        property({ type: String })
    ], Element.prototype, "id", void 0);
    __decorate([
        property({ type: String })
    ], Element.prototype, "maximizeBlockLocalization", void 0);
    Element = __decorate([
        customElement("uuip-wc-widget-wrapper")
    ], Element);
    WidgetWrapper.Element = Element;
})(WidgetWrapper || (WidgetWrapper = {}));
