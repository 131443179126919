var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { MaximizeAreaBlock } from "../../components/MaximizeArea/MaximizeAreaBlock";
import { logger } from "@/sdk";
import { createTimeoutPromise, throttle } from "@/utils/helpers";
import { WINDOW_LISTENERS_HUB } from "@/utils/WindowListenersHub";
import { customElement, internalProperty, LitElement, property } from "lit-element";
import { nothing } from "lit-html";
import style from "./MaximizeAreaHost.scss";
export var MaximizeAreaHost;
(function (MaximizeAreaHost) {
    // Host <=> Block communication events
    let AuxEvents;
    (function (AuxEvents) {
        AuxEvents["MaximizeResponse"] = "maximize-host-response";
        AuxEvents["MinimizeResponse"] = "minimize-host-response";
        AuxEvents["ResizeResponse"] = "resize-host-response";
    })(AuxEvents = MaximizeAreaHost.AuxEvents || (MaximizeAreaHost.AuxEvents = {}));
    // Host outer events
    let Events;
    (function (Events) {
        Events["MaximizeTransitionStart"] = "maximize-transition-start";
        Events["MaximizeTransitionEnd"] = "maximize-transition-end";
        Events["MinimizeTransitionStart"] = "minimize-transition-start";
        Events["MinimizeTransitionEnd"] = "minimize-transition-end";
        Events["Resized"] = "resized";
    })(Events = MaximizeAreaHost.Events || (MaximizeAreaHost.Events = {}));
    const areasMap = new Map();
    /**
     * @element uuip-wc-maximize-area-host
     *
     * @fires maximize-transition-start
     * @fires maximize-transition-end
     * @fires minimize-transition-start
     * @fires minimize-transition-end
     *
     * @fires resized
     */
    let Element = class Element extends LitElement {
        constructor() {
            super(...arguments);
            this.onToggleBlockRequest = (e) => this.toggleAreaResponse(e);
            this.onDisconnectBlockRequest = (e) => {
                const { areaName, blockName } = e.detail;
                if (this.areaName && this.areaName === areaName) {
                    if (blockName && this.currentMaximizedBlockName === blockName) {
                        this.currentMaximizedBlockName = undefined;
                    }
                }
            };
            this.onWindowResize = throttle(() => {
                if (this.areaName && this.currentMaximizedBlockName) {
                    this.dispatchResized({ areaName: this.areaName, blockName: this.currentMaximizedBlockName });
                }
            });
            // Support resize area
            this.resizeObserver = new window.ResizeObserver(this.onWindowResize);
        }
        toggleAreaResponse(e) {
            const { areaName, blockName } = e.detail;
            if (this.areaName && this.areaName === areaName) {
                if (blockName) {
                    // No Blocks Maximized => maximizing blockName
                    if (!this.currentMaximizedBlockName) {
                        this.dispatchMaximized({ areaName, blockName });
                        this.currentMaximizedBlockName = blockName;
                    }
                    // Minimize current maximized block
                    else if (this.currentMaximizedBlockName === blockName) {
                        this.dispatchMinimized({ areaName, blockName });
                        this.currentMaximizedBlockName = undefined;
                    }
                    // Minimize current & maximize another
                    else if (this.currentMaximizedBlockName !== blockName) {
                        // Minimize current
                        this.dispatchMinimized({ areaName, blockName: this.currentMaximizedBlockName });
                        // Maximize next
                        this.dispatchMaximized({ areaName, blockName });
                        this.currentMaximizedBlockName = blockName;
                    }
                }
            }
        }
        // Emitting outer component events
        emitComponentUpdate(eventName, { areaName, blockName }) {
            this.dispatchEvent(new CustomEvent(eventName, {
                detail: {
                    areaName,
                    blockName
                }
            }));
        }
        // Dispatching through WINDOW_LISTENERS_HUB
        dispatchAuxHubUpdate(eventName, { areaName, blockName }) {
            WINDOW_LISTENERS_HUB.dispatch(new CustomEvent(eventName, {
                detail: {
                    areaName,
                    blockName,
                    rect: this.getBoundingClientRect()
                }
            }));
        }
        dispatchMaximized({ areaName, blockName }) {
            this.emitComponentUpdate(Events.MaximizeTransitionStart, { areaName, blockName });
            const unsubscribers = [];
            // Awaiting race of (MaximizeTransitionStart + MaximizeTransitionEnd) OR (Timeout)
            Promise.race([
                // Awaiting same block maximize start + end events
                Promise.all([
                    new Promise(resolve => {
                        unsubscribers.push(WINDOW_LISTENERS_HUB.once(MaximizeAreaBlock.AuxEvents.MaximizeStart, () => resolve(), (e) => {
                            const { areaName: areaNameStart, blockName: blockNameStart } = e.detail;
                            return areaName === areaNameStart && blockName === blockNameStart;
                        }));
                    }),
                    new Promise(resolve => {
                        unsubscribers.push(WINDOW_LISTENERS_HUB.once(MaximizeAreaBlock.AuxEvents.MaximizeEnd, () => resolve(), (e) => {
                            const { areaName: areaNameEnd, blockName: blockNameEnd } = e.detail;
                            return areaName === areaNameEnd && blockName === blockNameEnd;
                        }));
                    })
                ]),
                // Or failing due to some animation issue
                createTimeoutPromise(MaximizeAreaBlock.TRANSITION_FAILED_TIMEOUT)
            ]).finally(() => {
                this.emitComponentUpdate(Events.MaximizeTransitionEnd, { areaName, blockName });
                unsubscribers.forEach(u => u());
                unsubscribers.length = 0;
            });
            this.dispatchAuxHubUpdate(AuxEvents.MaximizeResponse, { areaName, blockName });
        }
        dispatchMinimized({ areaName, blockName }) {
            this.emitComponentUpdate(Events.MinimizeTransitionStart, { areaName, blockName });
            const unsubscribers = [];
            // Awaiting race of (MinimizeTransitionStart + MinimizeTransitionEnd) OR (Timeout)
            Promise.race([
                // Awaiting same block minimize start + end events
                Promise.all([
                    new Promise(resolve => {
                        unsubscribers.push(WINDOW_LISTENERS_HUB.once(MaximizeAreaBlock.AuxEvents.MinimizeStart, () => resolve(), (e) => {
                            const { areaName: areaNameStart, blockName: blockNameStart } = e.detail;
                            return areaName === areaNameStart && blockName === blockNameStart;
                        }));
                    }),
                    new Promise(resolve => {
                        unsubscribers.push(WINDOW_LISTENERS_HUB.once(MaximizeAreaBlock.AuxEvents.MinimizeEnd, () => resolve(), (e) => {
                            const { areaName: areaNameEnd, blockName: blockNameEnd } = e.detail;
                            return areaName === areaNameEnd && blockName === blockNameEnd;
                        }));
                    })
                ]),
                // Or failing due to some animation issue
                createTimeoutPromise(MaximizeAreaBlock.TRANSITION_FAILED_TIMEOUT)
            ]).finally(() => {
                this.emitComponentUpdate(Events.MinimizeTransitionEnd, { areaName, blockName });
                unsubscribers.forEach(u => u());
                unsubscribers.length = 0;
            });
            this.dispatchAuxHubUpdate(AuxEvents.MinimizeResponse, { areaName, blockName });
        }
        dispatchResized({ areaName, blockName }) {
            this.dispatchAuxHubUpdate(AuxEvents.ResizeResponse, { areaName, blockName });
            this.emitComponentUpdate(Events.Resized, { areaName, blockName });
        }
        connectedCallback() {
            super.connectedCallback();
            WINDOW_LISTENERS_HUB.on(MaximizeAreaBlock.AuxEvents.ToggleRequest, this.onToggleBlockRequest);
            WINDOW_LISTENERS_HUB.on(MaximizeAreaBlock.AuxEvents.DisconnectRequest, this.onDisconnectBlockRequest);
            WINDOW_LISTENERS_HUB.on("resize", this.onWindowResize);
            this.resizeObserver.observe(this);
        }
        disconnectedCallback() {
            super.disconnectedCallback();
            WINDOW_LISTENERS_HUB.off(MaximizeAreaBlock.AuxEvents.ToggleRequest, this.onToggleBlockRequest);
            WINDOW_LISTENERS_HUB.off(MaximizeAreaBlock.AuxEvents.DisconnectRequest, this.onDisconnectBlockRequest);
            WINDOW_LISTENERS_HUB.off("resize", this.onWindowResize);
            this.resizeObserver.disconnect();
        }
        updated(changedProperties) {
            super.updated(changedProperties);
            changedProperties.forEach((oldValue, name) => {
                if (name === "areaName") {
                    if (oldValue) {
                        if (areasMap.has(oldValue)) {
                            areasMap.delete(oldValue);
                        }
                    }
                    if (this.areaName) {
                        if (!areasMap.has(this.areaName)) {
                            areasMap.set(this.areaName, this);
                        }
                        else {
                            logger.error(`[MaximizeAreaHost] Area with name ${this.areaName} already exists!`);
                        }
                    }
                    else {
                        logger.error(`[MaximizeAreaHost] Area name is not defined`);
                    }
                }
            });
        }
        static get styles() {
            return [style];
        }
        render() {
            return nothing;
        }
    };
    __decorate([
        property({ type: String, reflect: true })
    ], Element.prototype, "areaName", void 0);
    __decorate([
        property({ type: Boolean, reflect: true })
    ], Element.prototype, "resizeAnimate", void 0);
    __decorate([
        internalProperty()
    ], Element.prototype, "currentMaximizedBlockName", void 0);
    Element = __decorate([
        customElement("uuip-wc-maximize-area-host")
    ], Element);
    MaximizeAreaHost.Element = Element;
})(MaximizeAreaHost || (MaximizeAreaHost = {}));
