var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import "@momentum-ui/web-components";
import { customElement, html, LitElement } from "lit-element";
import style from "./ContentError.scss";
export var ContentError;
(function (ContentError) {
    ContentError.DEFAULT_ERROR_TEXT = "An error occured";
    /**
     * @element uuip-wc-content-error
     */
    let Element = class Element extends LitElement {
        static get styles() {
            return [style];
        }
        render() {
            return html `
        <div class="content-error">
          <div class="content-error-text">
            <slot>${ContentError.DEFAULT_ERROR_TEXT}</slot>
          </div>
          <md-icon ariaLabel="error-legacy" color="black" name="icon-error-legacy_24" title="Error"></md-icon>
        </div>
      `;
        }
    };
    Element = __decorate([
        customElement("uuip-wc-content-error")
    ], Element);
    ContentError.Element = Element;
})(ContentError || (ContentError = {}));
