export const throttle = (fn, delay = 1000 / 60) => {
    let lastTimeoutRef = undefined;
    let last = Date.now();
    // Throttling
    const next = (...args) => {
        const now = Date.now();
        window.clearTimeout(lastTimeoutRef);
        if (!last || now - last >= delay) {
            last = now;
            fn.call(fn, ...args);
        }
        else {
            lastTimeoutRef = window.setTimeout(() => next(...args), delay - (now - last));
        }
    };
    return (...args) => next(...args);
};
export const createTimeoutPromise = (timeout = 0) => new Promise(resolve => window.setTimeout(resolve, timeout));
export const createElementTransitionEndPromise = (element, transitionPropertyName, transitionEndFailMsTimeout = 2000) => new Promise(resolve => {
    // Awaiting transform end or timeout
    const onTransitionEnd = (e) => {
        if (e.propertyName === transitionPropertyName) {
            onTransitionEnd.clear();
            resolve();
        }
    };
    element.addEventListener("transitionend", onTransitionEnd);
    // Setup clear
    onTransitionEnd.clear = () => {
        // Remove timeout "clear"
        window.clearTimeout(onTransitionEnd.timeoutRef);
        // Remove Listener
        element.removeEventListener("transitionend", onTransitionEnd);
    };
    // Start timeout
    onTransitionEnd.timeoutRef = window.setTimeout(() => onTransitionEnd.clear(), transitionEndFailMsTimeout);
});
export const isCoordCloseToRect = (coord, rect, distance) => {
    return (rect.left - distance <= coord.x &&
        rect.right + distance >= coord.x &&
        rect.top - distance <= coord.y &&
        rect.bottom + distance >= coord.y);
};
