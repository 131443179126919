var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { customElement, html, LitElement, property } from "lit-element";
import style from "./EmptyState.scss";
let EmptyState = class EmptyState extends LitElement {
    constructor() {
        super(...arguments);
        this.illustrationName = "four_managers";
        this.illustrationAlt = "";
        this.illustrationSrc = "";
    }
    get imagePath() {
        if (this.illustrationSrc && this.illustrationSrc.trim().length !== 0) {
            return this.illustrationSrc.trim();
        }
        return require(`@img/${this.illustrationName}.svg`);
    }
    render() {
        return html `
      <div class="empty-state" part="container">
        <div class="empty-state__container">
          <img part="img" aria-hidden="true" src="${this.imagePath}" alt="${this.illustrationAlt}" class="empty-state__illustration" />
          <slot></slot>
        </div>
      </div>
    `;
    }
    static get styles() {
        return [style];
    }
};
__decorate([
    property({ type: String, attribute: "illustration-name" })
], EmptyState.prototype, "illustrationName", void 0);
__decorate([
    property({ type: String, attribute: "illustration-alt" })
], EmptyState.prototype, "illustrationAlt", void 0);
__decorate([
    property({ type: String, attribute: "illustration-src" })
], EmptyState.prototype, "illustrationSrc", void 0);
EmptyState = __decorate([
    customElement("uuip-wc-empty-state")
], EmptyState);
export { EmptyState };
