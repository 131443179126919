var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import "../../components/CollapseArea/components/CollapseToggler";
import { createElementTransitionEndPromise, isCoordCloseToRect, throttle } from "@/utils/helpers";
import { WINDOW_LISTENERS_HUB } from "@/utils/WindowListenersHub";
import { customElement, html, internalProperty, LitElement, property, query } from "lit-element";
import { classMap } from "lit-html/directives/class-map";
import style from "./CollapseArea.scss";
export var CollapseArea;
(function (CollapseArea) {
    CollapseArea.TRANSITION_FAILED_TIMEOUT = 2000; //ms
    const DEFAULT_TOGGLER_MOUSE_VISIBLE_DISTANCE = 100; //px
    let Direction;
    (function (Direction) {
        Direction["Left"] = "left";
        Direction["Right"] = "right";
    })(Direction = CollapseArea.Direction || (CollapseArea.Direction = {}));
    let Events;
    (function (Events) {
        Events["Toggle"] = "toggle";
        Events["ExpandTransitionStart"] = "expand-transition-start";
        Events["ExpandTransitionEnd"] = "expand-transition-end";
        Events["CollapseTransitionStart"] = "collapse-transition-start";
        Events["CollapseTransitionEnd"] = "collapse-transition-end";
    })(Events = CollapseArea.Events || (CollapseArea.Events = {}));
    /**
     * @element uuip-wc-collapse-area
     *
     * @fires toggle
     *
     * @fires expand-transition-start
     * @fires expand-transition-end
     * @fires collapse-transition-start
     * @fires collapse-transition-end
     */
    let Element = class Element extends LitElement {
        constructor() {
            super(...arguments);
            this.collapsed = false;
            this.direction = Direction.Left;
            // Display toggler on mouse over selectors that are not in collpase-area
            this.forceTogglerVisibilityOnMouseOverSelectors = "";
            // Distance to display toggler when mouse close to it in px
            this.togglerMouseVisibleDistance = DEFAULT_TOGGLER_MOUSE_VISIBLE_DISTANCE;
            this.isMouseCloseToToggler = false;
            this.isMouseInSelfArea = false;
            this.isMouseOverSelector = false;
            this.isMouseOverOnToggler = false;
            this.onSelfMouseEnter = throttle(() => (this.isMouseInSelfArea = true));
            this.onSelfMouseLeave = throttle(() => (this.isMouseInSelfArea = false));
            this.onWindowMouseMove = throttle((e) => {
                const _path = e.composedPath();
                const _selectors = Array.isArray(this.forceTogglerVisibilityOnMouseOverSelectors)
                    ? this.forceTogglerVisibilityOnMouseOverSelectors
                    : this.forceTogglerVisibilityOnMouseOverSelectors.split(" ");
                if (_path.length) {
                    this.isMouseOverSelector = !!_selectors.find(selector => !!_path.find((eventTarget) => {
                        return (eventTarget && eventTarget.matches && eventTarget.matches(selector));
                    }));
                }
                const { clientX: x, clientY: y } = e;
                const { top, right, bottom, left } = this.toggler.getBoundingClientRect();
                this.isMouseCloseToToggler = isCoordCloseToRect({ x, y }, { top, right, bottom, left }, this.togglerMouseVisibleDistance);
            });
            this.onWindowMouseLeave = throttle(() => {
                this.isMouseOverSelector = false;
                this.isMouseCloseToToggler = false;
            });
            this.onTogglerMouseOver = throttle(() => (this.isMouseOverOnToggler = true));
            this.onTogglerMouseOut = throttle(() => (this.isMouseOverOnToggler = false));
        }
        get isTogglerVisible() {
            return this.isMouseCloseToToggler || this.isMouseInSelfArea || this.isMouseOverSelector;
        }
        connectedCallback() {
            super.connectedCallback();
            this.addEventListener("mouseenter", this.onSelfMouseEnter);
            this.addEventListener("mouseleave", this.onSelfMouseLeave);
            WINDOW_LISTENERS_HUB.on("mouseenter", this.onWindowMouseMove);
            WINDOW_LISTENERS_HUB.on("mousemove", this.onWindowMouseMove);
            WINDOW_LISTENERS_HUB.on("mouseleave", this.onWindowMouseLeave);
        }
        disconnectedCallback() {
            super.disconnectedCallback();
            this.removeEventListener("mouseenter", this.onSelfMouseEnter);
            this.removeEventListener("mouseleave", this.onSelfMouseLeave);
            WINDOW_LISTENERS_HUB.off("mouseenter", this.onWindowMouseMove);
            WINDOW_LISTENERS_HUB.off("mousemove", this.onWindowMouseMove);
            WINDOW_LISTENERS_HUB.off("mouseleave", this.onWindowMouseLeave);
        }
        update(changedProperties) {
            super.update(changedProperties);
            changedProperties.forEach((oldValue, name) => {
                if (name === "collapsed") {
                    if (oldValue !== this.collapsed && typeof oldValue === "boolean") {
                        this.dispatchTransitionStateUpdates();
                    }
                }
            });
        }
        static get styles() {
            return [style];
        }
        emitComponentUpdate(eventName, detail) {
            this.dispatchEvent(new CustomEvent(eventName, { detail }));
        }
        //this dispatch have to be called in "update" (not in "updated") - before DOM udpates
        dispatchTransitionStateUpdates() {
            return __awaiter(this, void 0, void 0, function* () {
                const { collapsed, direction } = this;
                const detail = { collapsed, direction };
                // Emit start
                this.emitComponentUpdate(collapsed ? Events.CollapseTransitionStart : Events.ExpandTransitionStart, detail);
                // Bind & resolve "width" transition animation promise
                yield createElementTransitionEndPromise(this, "width", CollapseArea.TRANSITION_FAILED_TIMEOUT);
                // Emit end
                this.emitComponentUpdate(collapsed ? Events.CollapseTransitionEnd : Events.ExpandTransitionEnd, detail);
            });
        }
        toggle(e) {
            this.emitComponentUpdate(CollapseArea.Events.Toggle, {
                collapsed: e.detail.collapsed,
                direction: this.direction
            });
        }
        render() {
            return html `
        <div class="collapse-area ${classMap({ hovered: this.isMouseOverOnToggler })}">
          <div class="collapse-area-inner">
            <slot></slot>
          </div>
          <uuip-wc-collapse-toggler
            .direction="${this.direction}"
            .collapsed="${this.collapsed}"
            .visible="${this.isTogglerVisible}"
            @toggle="${(e) => this.toggle(e)}"
            @mouseover="${() => this.onTogglerMouseOver()}"
            @mouseout="${() => this.onTogglerMouseOut()}"
          ></uuip-wc-collapse-toggler>
        </div>
      `;
        }
    };
    __decorate([
        property({ type: Boolean, reflect: true })
    ], Element.prototype, "collapsed", void 0);
    __decorate([
        property({ type: String, reflect: true })
    ], Element.prototype, "direction", void 0);
    __decorate([
        property({ type: String })
    ], Element.prototype, "forceTogglerVisibilityOnMouseOverSelectors", void 0);
    __decorate([
        property({
            type: Number,
            attribute: "toggler-mouse-visible-distance"
        })
    ], Element.prototype, "togglerMouseVisibleDistance", void 0);
    __decorate([
        internalProperty()
    ], Element.prototype, "isMouseCloseToToggler", void 0);
    __decorate([
        internalProperty()
    ], Element.prototype, "isMouseInSelfArea", void 0);
    __decorate([
        internalProperty()
    ], Element.prototype, "isMouseOverSelector", void 0);
    __decorate([
        internalProperty()
    ], Element.prototype, "isMouseOverOnToggler", void 0);
    __decorate([
        query("uuip-wc-collapse-toggler")
    ], Element.prototype, "toggler", void 0);
    Element = __decorate([
        customElement("uuip-wc-collapse-area")
    ], Element);
    CollapseArea.Element = Element;
})(CollapseArea || (CollapseArea = {}));
