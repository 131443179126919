var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import "../../../../components/Notifications/components/ToasterNotification/Content/ToasterNotificationItemContent";
import "../../../../components/Notifications/components/ToasterNotification/ToasterNotification";
import "@momentum-ui/web-components";
import { Notifications } from "@uuip/unified-ui-platform-sdk";
import { customElement, html, internalProperty, LitElement, property } from "lit-element";
import { classMap } from "lit-html/directives/class-map";
import { repeat } from "lit-html/directives/repeat";
import style from "./ToasterNotificationsHost.scss";
export var ToasterNotificationsHost;
(function (ToasterNotificationsHost) {
    /**
     * @element uuip-wc-toaster-notifications-host
     */
    let Element = class Element extends LitElement {
        constructor() {
            super(...arguments);
            this.notifications = [];
            this.position = "top-right";
            this.height = "height-auto";
            this.renderNotifications = [];
            // Appear map need to prevent re-animatoin on repeat re-concilation
            this.appearedMap = {};
        }
        static get styles() {
            return style;
        }
        updated(changedProperties) {
            super.updated(changedProperties);
            changedProperties.forEach((oldValue, name) => {
                if (name === "notifications") {
                    this.addToRenderNotifications();
                    // To be animated - removing from notifications handling in ToasterNotification onStatusUpdate
                }
            });
        }
        addToRenderNotifications() {
            const old_notifications = [...this.renderNotifications];
            let new_notifications = [];
            this.notifications.forEach(n => {
                const idx = old_notifications.findIndex(_n => _n.timestamp === n.timestamp);
                if (idx === -1) {
                    // Add New
                    new_notifications = [...old_notifications, n];
                }
                else {
                    // Update Existing
                    new_notifications = [
                        ...old_notifications.slice(0, idx),
                        n,
                        ...old_notifications.slice(idx + 1, old_notifications.length)
                    ];
                }
            });
            new_notifications.sort(Notifications.Service.sortByTimestampsFn);
            if (new_notifications.length) {
                this.renderNotifications = [...new_notifications];
            }
        }
        removeFromRenderNotifications(n) {
            const idx = this.renderNotifications.findIndex(_n => _n.timestamp === n.timestamp);
            if (idx !== -1) {
                this.renderNotifications = [
                    ...this.renderNotifications.slice(0, idx),
                    ...this.renderNotifications.slice(idx + 1)
                ];
            }
        }
        render() {
            const listClassInfo = {
                [this.position]: this.position,
                [this.height]: this.height,
                ["d-none"]: !this.renderNotifications.length
            };
            return html `
        <div part="host" class="list ${classMap(listClassInfo)}">
          <slot name="before-list"></slot>
          ${repeat(this.renderNotifications, n => n.timestamp, n => html `
                <uuip-wc-toaster-notification
                  .notification="${n}"
                  .wasAppeared="${this.appearedMap[n.timestamp]}"
                  @appeared="${(e) => {
                this.appearedMap[e.detail.notification.timestamp] = true;
            }}"
                  @disappeared="${(e) => {
                this.removeFromRenderNotifications(e.detail.notification);
            }}"
                ></uuip-wc-toaster-notification>
              `)}
          <slot name="after-list"></slot>
        </div>
      `;
        }
    };
    __decorate([
        property({ type: Array })
    ], Element.prototype, "notifications", void 0);
    __decorate([
        property({ type: String })
    ], Element.prototype, "position", void 0);
    __decorate([
        property({ type: String })
    ], Element.prototype, "height", void 0);
    __decorate([
        internalProperty()
    ], Element.prototype, "renderNotifications", void 0);
    Element = __decorate([
        customElement("uuip-wc-toaster-notifications-host")
    ], Element);
    ToasterNotificationsHost.Element = Element;
})(ToasterNotificationsHost || (ToasterNotificationsHost = {}));
