var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import "./ModalNotification";
import "@momentum-ui/web-components";
import { customElement, html, LitElement, property } from "lit-element";
import { nothing } from "lit-html";
import { classMap } from "lit-html/directives/class-map";
import style from "./ModalNotificationsHost.scss";
export var ModalNotificationsHost;
(function (ModalNotificationsHost) {
    /**
     * @element uuip-wc-modal-notifications-host
     */
    let Element = class Element extends LitElement {
        constructor() {
            super(...arguments);
            this.notifications = [];
        }
        static get styles() {
            return [style];
        }
        get notification() {
            return this.notifications.length ? this.notifications[0] : null;
        }
        render() {
            const listClassInfo = {
                ["d-none"]: !this.notification
            };
            return html `
        <div class="list ${classMap(listClassInfo)}">
          ${this.notification
                ? html `
                <uuip-wc-modal-notification .notification="${this.notification}"></uuip-wc-modal-notification>
              `
                : nothing}
        </div>
      `;
        }
    };
    __decorate([
        property({ type: Array })
    ], Element.prototype, "notifications", void 0);
    Element = __decorate([
        customElement("uuip-wc-modal-notifications-host")
    ], Element);
    ModalNotificationsHost.Element = Element;
})(ModalNotificationsHost || (ModalNotificationsHost = {}));
