var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import "@momentum-ui/web-components";
import { Notifications } from "@uuip/unified-ui-platform-sdk";
import { customElement, html, internalProperty, LitElement, property } from "lit-element";
import { nothing } from "lit-html";
import style from "./ModalNotification.scss";
export var ModalNotification;
(function (ModalNotification) {
    class DataController {
        constructor(p) {
            this.isPositiveChoiceAvailable = true;
            this.isNegativeChoiceAvailable = false;
            this.isNeutralChoiceAvailable = false;
            this.localization = {
                positiveButtonText: "OK",
                negativeButtonText: "CANCEL",
                neutralButtonText: "SNOOZE"
            };
            this.text = p.text;
            this.localization = p.localization || this.localization;
            this.isNegativeChoiceAvailable =
                p.isNegativeChoiceAvailable !== undefined ? p.isNegativeChoiceAvailable : this.isNegativeChoiceAvailable;
            this.isNeutralChoiceAvailable =
                p.isNeutralChoiceAvailable !== undefined ? p.isNeutralChoiceAvailable : this.isNeutralChoiceAvailable;
        }
    }
    ModalNotification.DataController = DataController;
    /**
     * @element uuip-wc-modal-notification
     */
    let Element = class Element extends LitElement {
        constructor() {
            super(...arguments);
            this.isModalOpen = false;
        }
        static get styles() {
            return [style];
        }
        connectedCallback() {
            super.connectedCallback();
            this.openModal();
        }
        get hasDataController() {
            return this.notification.data instanceof DataController;
        }
        get dataController() {
            return this.hasDataController ? this.notification.data : null;
        }
        get text() {
            if (this.dataController) {
                return this.dataController.text;
            }
            return this.notification.data;
        }
        get positiveButtonText() {
            return this.hasDataController ? this.dataController.localization.positiveButtonText : "OK";
        }
        get negativeButtonText() {
            return this.hasDataController ? this.dataController.localization.positiveButtonText : "CANCEL";
        }
        get neutralButtonText() {
            return this.hasDataController ? this.dataController.localization.positiveButtonText : "SNOOZE";
        }
        openModal() {
            this.isModalOpen = true;
        }
        closeModal(reason = Notifications.ItemMeta.DeactivateEventReason.UserNegative) {
            this.isModalOpen = false;
            this.notification.deactivate(reason);
        }
        handlePositiveAcknowledge() {
            this.closeModal(Notifications.ItemMeta.DeactivateEventReason.UserPositive);
        }
        handleNegativeAcknowledge() {
            this.closeModal(Notifications.ItemMeta.DeactivateEventReason.UserNegative);
        }
        handleNeutralAcknowledge() {
            this.closeModal(Notifications.ItemMeta.DeactivateEventReason.UserNeutral);
        }
        renderPositiveButton() {
            return html `
        <md-button
          variant="primary"
          ariaLabel="${this.positiveButtonText}"
          @click="${() => this.handlePositiveAcknowledge()}"
        >
          <span>${this.positiveButtonText}</span>
        </md-button>
      `;
        }
        renderNegativeButton() {
            return html `
        <md-button
          variant="secondary"
          ariaLabel="${this.negativeButtonText}"
          @click="${() => this.handleNegativeAcknowledge()}"
        >
          <span>${this.negativeButtonText}</span>
        </md-button>
      `;
        }
        renderNeutralButton() {
            return html `
        <md-button
          variant="secondary"
          ariaLabel="${this.neutralButtonText}"
          @click="${() => this.handleNegativeAcknowledge()}"
        >
          <span>${this.neutralButtonText}</span>
        </md-button>
      `;
        }
        renderAllButtons() {
            if (!this.dataController) {
                return this.renderPositiveButton();
            }
            return html `
        ${this.dataController.isPositiveChoiceAvailable ? this.renderPositiveButton() : nothing}
        ${this.dataController.isNegativeChoiceAvailable ? this.renderNegativeButton() : nothing}
        ${this.dataController.isNeutralChoiceAvailable ? this.renderNeutralButton() : nothing}
      `;
        }
        render() {
            return html `
        <md-modal
          .htmlId="${`modal-${this.notification.timestamp}`}"
          .show=${this.isModalOpen}
          size="dialog"
          headerMessage="${this.notification.title}"
          hideFooter
          @close-modal="${() => {
                !this.dataController
                    ? this.handlePositiveAcknowledge()
                    : this.dataController.isNegativeChoiceAvailable
                        ? this.handleNegativeAcknowledge()
                        : this.dataController.isNeutralChoiceAvailable
                            ? this.handleNeutralAcknowledge()
                            : this.handlePositiveAcknowledge();
            }}"
        >
          <p>
            ${this.text}
          </p>
          <div slot="footer">
            ${this.renderAllButtons()}
          </div>
        </md-modal>
      `;
        }
    };
    __decorate([
        property({ type: Object })
    ], Element.prototype, "notification", void 0);
    __decorate([
        internalProperty()
    ], Element.prototype, "isModalOpen", void 0);
    Element = __decorate([
        customElement("uuip-wc-modal-notification")
    ], Element);
    ModalNotification.Element = Element;
})(ModalNotification || (ModalNotification = {}));
