var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import "@momentum-ui/web-components";
import { Notifications } from "@uuip/unified-ui-platform-sdk";
import { customElement, html, internalProperty, LitElement, property } from "lit-element";
import style from "./ToasterNotification.scss";
export var ToasterNotification;
(function (ToasterNotification) {
    const ANIMATION_DISABLED_CHECK_TIMEOUT = 2000; //ms - applying when "animationend" event was not fired after status change
    /**
     * @element uuip-wc-toaster-notification
     * @fires appeared
     * @fires disappeared
     */
    let Element = class Element extends LitElement {
        constructor() {
            super(...arguments);
            this.position = "top-right";
            this.wasAppeared = false;
            this.appear = true;
            this.disappear = false;
            this.animationDisabledTimeoutRef = undefined;
            this.onAnimationEnd = () => {
                if (this.disappear) {
                    this.emit("disappeared", { notification: this.notification });
                }
                else {
                    if (!this.wasAppeared && this.appear) {
                        this.emit("appeared", { notification: this.notification });
                    }
                }
                this.removeAnimationDisabledCheck();
            };
            this.onStatusUpdate = (status, reason) => {
                const wasDisappear = this.disappear;
                if (status !== Notifications.ItemMeta.Status.Activated) {
                    this.disappear = true;
                }
                if (!wasDisappear) {
                    this.setAnimationDisabledCheck();
                }
            };
        }
        static get styles() {
            return [style];
        }
        connectedCallback() {
            super.connectedCallback();
            this.addEventListener("animationend", this.onAnimationEnd);
            this.notification.addEventListener("statusUpdate", this.onStatusUpdate);
        }
        updated(changedProperties) {
            super.updated(changedProperties);
            changedProperties.forEach((oldValue, name) => {
                if (name === "appear") {
                    if (this.appear !== oldValue) {
                        if (this.appear) {
                            this.setAttribute("appear", "");
                        }
                        else {
                            this.removeAttribute("appear");
                        }
                    }
                }
                if (name === "disappear") {
                    if (this.disappear !== oldValue) {
                        if (this.disappear) {
                            this.setAttribute("disappear", "");
                        }
                        else {
                            this.removeAttribute("disappear");
                        }
                    }
                }
            });
        }
        disconnectedCallback() {
            super.disconnectedCallback();
            this.removeAnimationDisabledCheck();
            this.removeEventListener("animationend", this.onAnimationEnd);
            this.notification.removeEventListener("statusUpdate", this.onStatusUpdate);
        }
        setAnimationDisabledCheck() {
            this.removeAnimationDisabledCheck();
            this.animationDisabledTimeoutRef = window.setTimeout(() => this.onAnimationEnd(), ANIMATION_DISABLED_CHECK_TIMEOUT);
        }
        removeAnimationDisabledCheck() {
            window.clearTimeout(this.animationDisabledTimeoutRef);
            this.animationDisabledTimeoutRef = undefined;
        }
        emit(eventName, detail, bubbles = false, composed = false, cancelable = true) {
            this.dispatchEvent(new CustomEvent(eventName, {
                detail,
                bubbles,
                composed,
                cancelable
            }));
        }
        render() {
            return html `
        <div class="toaster">
          <uuip-wc-toaster-notification-item-content
            toast
            .notification="${this.notification}"
          ></uuip-wc-toaster-notification-item-content>
        </div>
      `;
        }
    };
    __decorate([
        property({ type: Object })
    ], Element.prototype, "notification", void 0);
    __decorate([
        property({ type: String, reflect: true })
    ], Element.prototype, "position", void 0);
    __decorate([
        property({ type: Boolean, reflect: true })
    ], Element.prototype, "wasAppeared", void 0);
    __decorate([
        internalProperty()
    ], Element.prototype, "appear", void 0);
    __decorate([
        internalProperty()
    ], Element.prototype, "disappear", void 0);
    Element = __decorate([
        customElement("uuip-wc-toaster-notification")
    ], Element);
    ToasterNotification.Element = Element;
})(ToasterNotification || (ToasterNotification = {}));
