var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import "../../components/ErrorDetail/ErrorDetail";
import { i18nMixin } from "@/mixins/i18n";
import { logger } from "@/sdk";
import "@momentum-ui/web-components";
import { customElement, html, internalProperty, LitElement, property, query } from "lit-element";
import { nothing } from "lit-html";
import style from "./ErrorModal.scss";
export var ErrorModal;
(function (ErrorModal) {
    let Element = class Element extends i18nMixin(LitElement) {
        constructor() {
            super(...arguments);
            this.isModalOpen = false;
            this.detail = null;
            this.localization = {};
            this.t = {
                okButtonText: "OK",
                headerText: "Error",
                errorActionsText: "Please try again."
            };
            this.defaultOkMap = {
                type: "ok",
                label: this.t.okButtonText,
                onClick: () => {
                    logger.info("[ErrorModal] Default error callback");
                }
            };
            this.okBtnMap = this.defaultOkMap;
        }
        updated(changedProperties) {
            super.updated(changedProperties);
            changedProperties.forEach((oldValue, name) => {
                var _a, _b, _c, _d;
                if (name === "detail" && this.detail) {
                    this.okBtnMap = ((_a = this.detail.buttons) === null || _a === void 0 ? void 0 : _a.find(obj => obj.type === "ok")) || this.defaultOkMap;
                }
                if (name === "isModalOpen") {
                    if (this.footerButton) {
                        const footerBtn = (_d = (_c = (_b = this.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelector("md-button")) === null || _c === void 0 ? void 0 : _c.shadowRoot) === null || _d === void 0 ? void 0 : _d.querySelector("button");
                        setTimeout(() => footerBtn === null || footerBtn === void 0 ? void 0 : footerBtn.focus());
                    }
                }
            });
        }
        evt(name, detail) {
            this.dispatchEvent(new CustomEvent(name, { bubbles: true, composed: true, detail }));
            logger.info("[ErrorModal] Dispatching", name, detail);
        }
        okHandler() {
            this.dispatchEvent(new CustomEvent("close-error", {
                composed: true,
                bubbles: true
            }));
            this.okBtnMap.onClick();
        }
        static get styles() {
            return [style];
        }
        btnClickHandler(btn) {
            this.handleCloseModal();
            btn.onClick();
        }
        handleCloseModal() {
            this.isModalOpen = false;
            this.evt("errorModal-show", false);
            this.requestUpdate("isModalOpen");
        }
        renderButton(btn) {
            if (btn.color) {
                return html `
          <md-button
            ariaLabel=${btn.label}
            class="footer-btn"
            color="${btn.color}"
            @click=${() => this.btnClickHandler(btn)}
            >${btn.label}
          </md-button>
        `;
            }
            return html `
        <md-button
          ariaLabel=${btn.label}
          class="footer-btn"
          variant=${btn.variant ? btn.variant : "primary"}
          @click=${() => this.btnClickHandler(btn)}
          >${btn.label}
        </md-button>
      `;
        }
        renterCustomFooterButton(buttons) {
            return html `
        ${buttons.map((btn) => this.renderButton(btn))}
      `;
        }
        renderModalFooter() {
            var _a, _b;
            return html `
        <div slot="footer" class="error-modal-footer">
          ${((_a = this.detail) === null || _a === void 0 ? void 0 : _a.buttons)
                ? this.renterCustomFooterButton((_b = this.detail) === null || _b === void 0 ? void 0 : _b.buttons)
                : this.renderButton({ type: "button", label: this.t.okButtonText, onClick: this.okHandler.bind(this) })}
        </div>
      `;
        }
        renderErrorDetail() {
            var _a;
            if ((_a = this.detail) === null || _a === void 0 ? void 0 : _a.trackingId) {
                return html `
          <uuip-wc-error-detail
            format="modal"
            .header="${this.detail.header ? this.detail.header : ""}"
            .trackingId="${this.detail.trackingId}"
            .message="${""}"
            .localization="${this.detail.localization ? this.detail.localization : {}}"
          ></uuip-wc-error-detail>
        `;
            }
            return nothing;
        }
        renderModalHeader() {
            var _a, _b, _c;
            if ((_a = this.detail) === null || _a === void 0 ? void 0 : _a.htmlHeadingElement) {
                return html `
          <h2 slot="header" aria-label="Error Modal" class="modal-header">
            ${((_b = this.detail) === null || _b === void 0 ? void 0 : _b.header) ? this.detail.header : this.t.headerText}
          </h2>
        `;
            }
            return html `
        <div slot="header" class="modal-header">
          ${((_c = this.detail) === null || _c === void 0 ? void 0 : _c.header) ? this.detail.header : this.t.headerText}
        </div>
      `;
        }
        render() {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            return html `
        <md-modal
          .show=${this.isModalOpen}
          size=${((_a = this.detail) === null || _a === void 0 ? void 0 : _a.modalType) ? (_b = this.detail) === null || _b === void 0 ? void 0 : _b.modalType : "dialog"}
          .hideFooter=${true}
          .hideHeader=${true}
          tabindex="0"
          aria-label=${((_c = this.detail) === null || _c === void 0 ? void 0 : _c.header) ? this.detail.header : this.t.headerText}
          ?showCloseButton=${!!((_d = this.detail) === null || _d === void 0 ? void 0 : _d.showCloseButton)}
          ?backdropClickExit=${!!((_e = this.detail) === null || _e === void 0 ? void 0 : _e.backdropClickExit)}
          ?noExitOnEsc=${!!((_f = this.detail) === null || _f === void 0 ? void 0 : _f.noExitOnEsc)}
          @close-modal=${() => this.handleCloseModal()}
          ariaDescription="modal-body"
        >
          ${this.renderModalHeader()}
          <div class="modal-body">
            <span>
              ${((_g = this.detail) === null || _g === void 0 ? void 0 : _g.message)
                ? this.detail.message
                : ((_h = this.detail) === null || _h === void 0 ? void 0 : _h.htmlMessage)
                    ? (_j = this.detail) === null || _j === void 0 ? void 0 : _j.htmlMessage
                    : this.t.errorActionsText}
            </span>
          </div>
          ${this.renderErrorDetail()} ${this.renderModalFooter()}
        </md-modal>
      `;
        }
    };
    __decorate([
        property({ type: Boolean })
    ], Element.prototype, "isModalOpen", void 0);
    __decorate([
        property({ type: Object })
    ], Element.prototype, "detail", void 0);
    __decorate([
        property({ type: Object })
    ], Element.prototype, "localization", void 0);
    __decorate([
        internalProperty()
    ], Element.prototype, "t", void 0);
    __decorate([
        query(".footer-btn")
    ], Element.prototype, "footerButton", void 0);
    Element = __decorate([
        customElement("uuip-wc-error-modal")
    ], Element);
    ErrorModal.Element = Element;
})(ErrorModal || (ErrorModal = {}));
