var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import "../../../../ErrorDetail/ErrorDetail";
import "@momentum-ui/web-components";
import { customElement, html, LitElement, property } from "lit-element";
import { nothing } from "lit-html";
import { ifDefined } from "lit-html/directives/if-defined";
import style from "./ToasterNotificationItemContent.scss";
export var ToasterNotificationItemContent;
(function (ToasterNotificationItemContent) {
    class DataController {
        constructor(p) {
            this.localization = {
                closeButtonText: "Close"
            };
            this.type = p.type;
            this.text = p.text;
            this.link = p.link;
            this.linkName = p.linkName;
            this.linkTooltip = p.linkTooltip;
            this.iconDetail = p.iconDetail;
            this.linkHandler = p.linkHandler;
            this.clickHandler = p.clickHandler;
            this.errorDetail = p.errorDetail;
            this.taskId = p.taskId;
            this.localization = p.localization || this.localization;
        }
    }
    ToasterNotificationItemContent.DataController = DataController;
    let Element = class Element extends LitElement {
        constructor() {
            super(...arguments);
            this.toast = false;
        }
        static get styles() {
            return [style];
        }
        get hasDataController() {
            return this.notification.data instanceof DataController;
        }
        get dataController() {
            return this.hasDataController ? this.notification.data : null;
        }
        get notificationData() {
            return this.notification.data;
        }
        get iconSize() {
            return this.toast ? undefined : "12";
        }
        get linkHref() {
            var _a;
            return ((_a = this.dataController) === null || _a === void 0 ? void 0 : _a.link) && !this.hasLinkHandler ? this.dataController.link : undefined;
        }
        get linkText() {
            var _a, _b;
            return ((_a = this.dataController) === null || _a === void 0 ? void 0 : _a.linkName) ? this.dataController.linkName : (_b = this.dataController) === null || _b === void 0 ? void 0 : _b.link;
        }
        // private get tooltipMessage() {
        //   return this.dataController?.linkContent ? "" : this.dataController?.link;
        // }
        get hasLinkHandler() {
            var _a;
            return typeof ((_a = this.dataController) === null || _a === void 0 ? void 0 : _a.linkHandler) === "function";
        }
        get hasClickHandler() {
            var _a;
            return typeof ((_a = this.dataController) === null || _a === void 0 ? void 0 : _a.clickHandler) === "function";
        }
        handleLinkClick(e) {
            var _a;
            if (this.hasLinkHandler) {
                e.preventDefault();
                (_a = this.dataController) === null || _a === void 0 ? void 0 : _a.linkHandler(this.notification);
            }
        }
        handleAlertClick() {
            var _a;
            if (this.hasClickHandler) {
                (_a = this.dataController) === null || _a === void 0 ? void 0 : _a.clickHandler(this.notification);
            }
        }
        get hasErrorDetail() {
            var _a;
            return !!((_a = this.dataController) === null || _a === void 0 ? void 0 : _a.errorDetail);
        }
        get errorDetailHeader() {
            var _a, _b, _c;
            return ((_b = (_a = this.dataController) === null || _a === void 0 ? void 0 : _a.errorDetail) === null || _b === void 0 ? void 0 : _b.header) ? (_c = this.dataController) === null || _c === void 0 ? void 0 : _c.errorDetail.header : undefined;
        }
        get errorDetailTrackingId() {
            var _a, _b, _c;
            return ((_b = (_a = this.dataController) === null || _a === void 0 ? void 0 : _a.errorDetail) === null || _b === void 0 ? void 0 : _b.trackingId) ? (_c = this.dataController) === null || _c === void 0 ? void 0 : _c.errorDetail.trackingId : undefined;
        }
        get errorDetailMessage() {
            var _a, _b;
            return (_b = (_a = this.dataController) === null || _a === void 0 ? void 0 : _a.errorDetail) === null || _b === void 0 ? void 0 : _b.message;
        }
        get errorDetailLocalization() {
            var _a, _b;
            return (_b = (_a = this.dataController) === null || _a === void 0 ? void 0 : _a.errorDetail) === null || _b === void 0 ? void 0 : _b.localization;
        }
        get iconDetail() {
            var _a, _b;
            return ((_a = this.dataController) === null || _a === void 0 ? void 0 : _a.iconDetail) ? (_b = this.dataController) === null || _b === void 0 ? void 0 : _b.iconDetail : undefined;
        }
        get hasIconName() {
            var _a, _b;
            return ((_b = (_a = this.dataController) === null || _a === void 0 ? void 0 : _a.iconDetail) === null || _b === void 0 ? void 0 : _b.iconName) ? true : false;
        }
        get hasIconUrl() {
            var _a, _b;
            return ((_b = (_a = this.dataController) === null || _a === void 0 ? void 0 : _a.iconDetail) === null || _b === void 0 ? void 0 : _b.iconUrl) ? true : false;
        }
        get closeButtonText() {
            var _a, _b, _c;
            return (_c = (_b = (_a = this.dataController) === null || _a === void 0 ? void 0 : _a.localization) === null || _b === void 0 ? void 0 : _b.closeButtonText) !== null && _c !== void 0 ? _c : "Close";
        }
        renderCustomIconTemplate() {
            const iconTemplate = (color, bgColor, iconName, iconColor, iconUrl, iconSize) => html `
        <md-badge
          slot="alert-icon"
          color=${ifDefined(color)}
          bgColor=${ifDefined(bgColor)}
          circle
          .small="${!this.toast}"
        >
          ${iconName
                ? html `
                <md-icon .name=${iconName} color=${ifDefined(iconColor)} size=${ifDefined(iconSize)}></md-icon>
              `
                : iconUrl
                    ? html `
                <img src=${iconUrl} class="wc-notification-badge-image" />
              `
                    : nothing}
        </md-badge>
      `;
            if (this.iconDetail && (this.hasIconName || this.hasIconUrl)) {
                return iconTemplate(this.iconDetail.color || undefined, this.iconDetail.bgColor || undefined, this.iconDetail.iconName || undefined, this.iconDetail.iconColor || undefined, this.iconDetail.iconUrl || undefined, this.iconSize);
            }
            switch (this.notificationData.type) {
                case "sms":
                    return iconTemplate("darkmint", undefined, "sms_16", "white", undefined, this.iconSize);
                case "chat":
                    return iconTemplate("blue", undefined, "chat-active_16", undefined, undefined, this.iconSize);
                case "messenger":
                    return iconTemplate(undefined, "#0078FF", "messenger_16", "white", undefined, this.iconSize);
                case "twitter":
                    return iconTemplate(undefined, "#1DA1F2", "twitter_16", "white", undefined, this.iconSize);
                case "whatsapp":
                    return iconTemplate(undefined, "#25D366", "whatsApp_16", "white", undefined, this.iconSize);
                default:
                    return html `
            <div slot="alert-icon" class="notification-slotted">
              <slot name="notif-icon"></slot>
            </div>
          `;
            }
        }
        renderNotificationLink() {
            if (this.linkText) {
                return html `
          <md-link
            class="wc-notification-link"
            href=${ifDefined(this.linkHref)}
            target="${this.linkHref ? "_blank" : "_self"}"
            @click="${(e) => this.handleLinkClick(e)}"
          >
            <p>${this.linkText}</p>
          </md-link>
        `;
            }
        }
        render() {
            var _a, _b;
            return html `
        <md-alert
          title=${this.notification.title}
          type=${this.notification.type}
          message=${ifDefined(!this.hasDataController ? this.notification.data : undefined)}
          show
          .internalClose="${false}"
          .closable="${this.toast}"
          .inline="${!this.toast}"
          btnlabel="${this.closeButtonText}"
          class="wc-notification-menu-item"
          @alert-close="${() => this.notification.pending()}"
        >
          ${this.dataController
                ? html `
                ${this.dataController.type || this.iconDetail
                    ? html `
                      ${this.renderCustomIconTemplate()}
                    `
                    : nothing}
                <div
                  slot="alert-body"
                  class="${`wc-notification-slotted-body ${this.hasClickHandler && "wc-notification-cursor-pointer"}`}"
                  @click="${this.handleAlertClick}"
                >
                  <p class="wc-notification-message">${this.dataController.text}</p>
                  ${(this.linkHref || this.hasLinkHandler) && ((_a = this.dataController) === null || _a === void 0 ? void 0 : _a.linkTooltip)
                    ? html `
                        <md-tooltip placement="bottom">
                          <div slot="tooltip-content">${(_b = this.dataController) === null || _b === void 0 ? void 0 : _b.linkTooltip}</div>
                          ${this.renderNotificationLink()}
                        </md-tooltip>
                      `
                    : this.renderNotificationLink()}
                  ${this.hasErrorDetail
                    ? html `
                        <uuip-wc-error-detail
                          format="toaster"
                          header=${ifDefined(this.errorDetailHeader)}
                          trackingId=${ifDefined(this.errorDetailTrackingId)}
                          .message=${this.errorDetailMessage}
                          .localization="${this.errorDetailLocalization}"
                        ></uuip-wc-error-detail>
                      `
                    : nothing}
                </div>
              `
                : nothing}
        </md-alert>
      `;
        }
    };
    __decorate([
        property({ type: Object })
    ], Element.prototype, "notification", void 0);
    __decorate([
        property({ type: Boolean })
    ], Element.prototype, "toast", void 0);
    Element = __decorate([
        customElement("uuip-wc-toaster-notification-item-content")
    ], Element);
    ToasterNotificationItemContent.Element = Element;
})(ToasterNotificationItemContent || (ToasterNotificationItemContent = {}));
